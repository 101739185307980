import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kastle/kastle/packages/mdx/src/components/Layout/Default.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Form = makeShortcode("Form");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "nous-contacter"
    }}>{`Nous contacter`}</h1>
    <Form url="https://form.typeform.com/to/N6rDCUjx" mdxType="Form" />
    <p>{`54610 MOREY / BELLEAU
Telephone: `}<a parentName="p" {...{
        "href": "tel:+33766802081",
        "target": "_blank",
        "rel": "noreferrer"
      }}>{`+33 7 66 80 20 81`}</a></p>
    <p>{`COORDONEES GPS: 48°49’35’ N / 06°09’45’ E`}</p>
    <p>{`SIREN: 878729292`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      